<template>
    <GroupAccommodations :can-edit="canEdit" :current-group-id="group.id" />
  </template>
  
  <script>
  import GroupAccommodations from "./GroupInformation/GroupAccommodations/GroupAccommodations.vue";
  import Roles from "@/modules/authnz/models/role";
  import {
    AUTH_STORE_NAMESPACE,
    AUTHENTICATED_USER_GETTER,
  } from "@/modules/authnz/models/store";
  
  export default {
    components: { GroupAccommodations },
    computed: {
      group() {
        return this.$store.state["app-groups"]?.currentGroup;
      },
      canEdit() {
        const currentRole =
          this.$store.getters[
            `${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`
          ].role;
        return Roles.roleIsAllowed(currentRole.role, [
          Roles.ORGANIZATION_ADMIN.role,
          Roles.ORGANIZATION_COORDINATOR.role,
        ]);
      },
    },
  };
  </script>
  